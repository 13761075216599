import { useContext, useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import PageContext from "src/components/Context/PageContext";
import { hasPermission } from "src/infrastructure/auth/PermissionUtils";
import { UserRoles } from "src/infrastructure/auth/UserRoles";
import { Title } from "./components/Title";
import { Grid } from "@mui/material";
import Button from "@jbhi-fi/lanyard-ui/components/Button/Button";
import { SelectField } from "src/components/Form";
import TextField from "@jbhi-fi/lanyard-ui/components/TextField/TextField";
import { GridCellParams, GridColDef } from "@mui/x-data-grid-pro";
import { dateStrToMoment } from "src/utils/formatUtil";
import { useHistory } from "react-router-dom";
import { getConsts } from "src/utils/consts";
import { ContentCopy, EditOutlined, SearchOutlined } from "@mui/icons-material";
import moment from "moment";
import DataGrid from "../Product/Desktop/components/DataGrid";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { MessageSearchOptions, MessageSearchOptionsType } from "src/models/messaging/MessageSearchOptions";
import { ConfirmModal, ConfirmModalState } from "src/components/Modal";
import { getMessagesAsync, getLatestMessagesAsync } from "src/utils/productAppApiHelper";
import { useCountry } from "src/components/Context/CountryContext";
import { getSubTypeLabel } from "src/models/messaging/MessageSubtypeLabels";
import { MessageSubtypes } from "src/models/messaging/MessageSubtypes";
import { GetProductResponse } from "src/models/product";
import { ProductMessageResponse } from "src/models/product/ProductMessageResponse";
import { CreateMessageModel } from "src/validationSchemas";
import { useTitle } from "src/hooks/useTitle";

const useStyles = makeStyles((theme) => ({
  cellRender: {
    "& .cellRender.active": {
      color: theme.palette.success.main,
      fontWeight: 700,
    },

    "& .cellRender.inactive": {
      color: theme.palette.error.main,
      fontWeight: 700,
    },

    "& .cellRender.future": {
      color: theme.palette.warning.main,
      fontWeight: 700,
    },
  },
}));

export const Messaging: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { country } = useCountry();
  const [searchParameter, setSearchParameter] = useState<MessageSearchOptions>(MessageSearchOptions.name);
  const [searchString, setSearchString] = useState<string>();
  const [contextData] = useContext(PageContext);
  const [searchResults, setSearchResults] = useState<any>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmModalState, setConfirmModalState] = useState<ConfirmModalState>({
    show: false,
  });

  useTitle("Product Messaging");

  const consts = getConsts();

  const editConfirmationStatus = {
    title: "Are you sure you want to edit this template for all products associated?",
    message: (
      <>
        <strong>Please note:</strong> This is an <strong>active</strong> template. Editing it will affect the template
        for all the products associated with this. Are you sure you want to edit it?
      </>
    ),
    confirmLabel: "Yes",
    cancelLabel: "No, go back",
  };
  const duplicateConfirmationStatus = {
    title: "Are you sure you want to duplicate this form?",
    message: (
      <>
        <strong>Please note:</strong> Duplicating will create a copy of the current form, which allows you to edit and
        create a new form based on existing information. Images will <strong>NOT</strong> be duplicated.
      </>
    ),
    confirmLabel: "Yes",
    cancelLabel: "No, go back",
  };

  const { userProfile } = contextData;

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Template name",
      flex: 2,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "subType",
      headerName: "Sub type",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      valueGetter: ({ row }) =>
        !row.finish || dateStrToMoment(row.finish) >= moment()
          ? !row.start || dateStrToMoment(row.start) <= moment()
            ? "Active"
            : "Future"
          : "Expired",
      cellClassName: (params: GridCellParams<any>) =>
        clsx("cellRender", {
          inactive: params.value === "Expired",
          active: params.value === "Active",
          future: params.value === "Future",
        }),
    },
    {
      field: "start",
      headerName: "Start",
      type: "date",
      valueFormatter: ({ value }) => {
        if (!value) return null;
        return moment(value).format("D MMM YYYY h:mm A");
      },
      flex: 1.5,
    },
    {
      field: "finish",
      headerName: "Finish",
      type: "date",
      valueFormatter: ({ value }) => {
        if (!value) return null;
        return moment(value).format("D MMM YYYY h:mm A");
      },
      flex: 1.5,
    },
    {
      field: "priority",
      headerName: "Priority",
      width: 100,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <>
            <Button
              type={"iconOnly"}
              icon={<EditOutlined />}
              onClick={() => {
                setConfirmModalState({
                  ...editConfirmationStatus,
                  show: true,
                  onConfirm: () => {
                    history.push(`/${consts.page.messaging}/${row.id}/edit`);
                  },
                  onCancel: () => {
                    setConfirmModalState({ show: false });
                  },
                });
              }}
            />
            <Button
              type={"iconOnly"}
              icon={<ContentCopy />}
              onClick={() => {
                setConfirmModalState({
                  ...duplicateConfirmationStatus,
                  show: true,
                  onConfirm: () => {
                    history.push(`/${consts.page.messaging}/${row.id}/copy`);
                  },
                  onCancel: () => {
                    setConfirmModalState({ show: false });
                  },
                });
              }}
            />
          </>
        );
      },
    },
  ];

  const mapResultWithSubTypeLabels = (result: CreateMessageModel[]) => {
    return result.map((message: CreateMessageModel) => ({
      ...message,
      subType: message.subType ? getSubTypeLabel(message.subType as MessageSubtypes) : message.subType
    }));
  };

  const fetchLatestMessages = async () => {
    try {
      setIsLoading(true);
      const result = await getLatestMessagesAsync(country);
      if (result === null) {
        setHasError(true);
        setErrorMessage("There has been an error fetching the data. Please try again later.");
      } else {
        const mappedResult = mapResultWithSubTypeLabels(result);
        setSearchResults(mappedResult);
      }
    } catch (e) {
      setHasError(true);
      setErrorMessage("There has been an error fetching the data. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLatestMessages();
  }, [country]);

  const onCreateButtonClick = () => {
    history.push(consts.routerPath.newMessage);
  };

  const handleSearch = async () => {
    try {
      setIsLoading(true);
      let result;
  
      if(!searchString){
        history.push({
          pathname: `${consts.routerPath.messaging}/messages`
        });
        result = await getLatestMessagesAsync(country);
      }
      else {
        const searchParam = searchParameter === MessageSearchOptions.name ? "name" : "sku";
  
        history.push({
          pathname: `${consts.routerPath.messaging}/messages`,
          search: `?${searchParam}=${searchString}`,
        });
  
        result = await getMessagesAsync(searchString, searchParam, country);
      }
  
      if (result === null) {
        setHasError(true);
        setErrorMessage("There has been an error fetching the data. Please try again later.");
      } else {
        const mappedResult = mapResultWithSubTypeLabels(result);
        setSearchResults(mappedResult);
      }
    } catch (e) {
      setHasError(true);
      setErrorMessage("There has been an error fetching the data. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

    
  useEffect(() => {
      handleSearch();
  }, [location.search]);

  if (!hasPermission(UserRoles.ProductMessaging, userProfile.roles)) {
    window.location.href = "/";
    return null;
  }

  return (
    <Grid container justifySelf={"center"} paddingTop={5} spacing={3}>
      <Grid item xs={9} paddingBottom={4}>
        <Title>Product Messaging</Title>
      </Grid>

      <Grid item xs={3} alignContent={"right"}>
        <Button type={"primary"} label={"Create New Template"} onClick={onCreateButtonClick} fullWidth />
      </Grid>

      <Grid item xs={3}>
        <SelectField
          name={"searchParameter"}
          label={"Search By"}
          onChange={setSearchParameter}
          value={searchParameter}
          options={MessageSearchOptionsType}
        />
      </Grid>

      <Grid item xs={7}>
        <TextField
          id={"productSearchBar"}
          name={"productSearchBar"}
          icon={<SearchOutlined style = {{paddingRight: '4px'}} />}
          placeholder={
            searchParameter === MessageSearchOptions.name
              ? "Search all templates by name"
              : "Search all templates by associated SKU"
          }
          onChange={(e) => setSearchString(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
          hasError={hasError}
          errorMsg={errorMessage}
        />
      </Grid>

      <Grid item xs={2}>
        <Button type="secondary" onClick={handleSearch} fullWidth>
          <SearchOutlined />
        </Button>
      </Grid>

      <Grid item xs={12} className={classes.cellRender}>
        <DataGrid columns={columns} rows={searchResults && searchResults.length > 0 ? searchResults : []} isLoading={isLoading} hideFooter autoHeight />
      </Grid>
      
      <ConfirmModal state={confirmModalState} />
    </Grid>
  );
};
